
import { Component, Vue } from 'vue-property-decorator';
import Footer from '@/components/Footer.vue';
import Header from '@/components/Header.vue';
import { reauthenticate, TOKENS } from './shared/auth';

@Component({
  components: {
    Footer,
    Header,
  },
})
export default class App extends Vue {
  constructor() {
    super();

    if (!TOKENS.LOGGEDIN && window.location.pathname.indexOf('/auth') !== 0) {
      reauthenticate(this.$router.currentRoute.path);
    }
  }
}
