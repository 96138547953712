
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Footer extends Vue {
  version = 'dev';

  constructor() {
    super();

    this.version = process.env.VUE_APP_VERSION || '2.0.0';
  }
}
