
import BoldIcon from 'vue-material-design-icons/FormatBold.vue';
import ItalicIcon from 'vue-material-design-icons/FormatItalic.vue';
import StrikethroughIcon from 'vue-material-design-icons/FormatStrikethroughVariant.vue';
import UnderlineIcon from 'vue-material-design-icons/FormatUnderline.vue';
import QuoteIcon from 'vue-material-design-icons/FormatQuoteClose.vue';
import H1Icon from 'vue-material-design-icons/FormatHeader1.vue';
import H2Icon from 'vue-material-design-icons/FormatHeader2.vue';
import H3Icon from 'vue-material-design-icons/FormatHeader3.vue';
import BulletListIcon from 'vue-material-design-icons/FormatListBulleted.vue';
import NumberListIcon from 'vue-material-design-icons/FormatListNumbered.vue';
import ParagraphIcon from 'vue-material-design-icons/FormatParagraph.vue';
import HorizontalRuleIcon from 'vue-material-design-icons/BorderHorizontal.vue';
import RedoIcon from 'vue-material-design-icons/Redo.vue';
import UndoIcon from 'vue-material-design-icons/Undo.vue';
import LinkIcon from 'vue-material-design-icons/LinkVariant.vue';
import LinkBreakIcon from 'vue-material-design-icons/LinkVariantOff.vue';
import CheckIcon from 'vue-material-design-icons/Check.vue';
import MenuIcon from 'vue-material-design-icons/DotsHorizontal.vue';
import CollapseIcon from 'vue-material-design-icons/ArrowCollapseUp.vue';
import './Editor.scss';
import { Editor, EditorContent, EditorMenuBar, EditorMenuBubble } from 'tiptap';
import {
  Blockquote,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  Bold,
  Italic,
  Link,
  Strike,
  Underline,
  History,
} from 'tiptap-extensions';
import { Component, Prop } from 'vue-property-decorator';
import VueWithStore from '@/store/classTypes';
// import Image from './EditorImage';

@Component({
  components: {
    EditorContent,
    EditorMenuBar,
    EditorMenuBubble,
    BoldIcon,
    ItalicIcon,
    StrikethroughIcon,
    UnderlineIcon,
    QuoteIcon,
    H1Icon,
    H2Icon,
    H3Icon,
    BulletListIcon,
    NumberListIcon,
    ParagraphIcon,
    HorizontalRuleIcon,
    UndoIcon,
    RedoIcon,
    LinkIcon,
    LinkBreakIcon,
    CheckIcon,
    MenuIcon,
    CollapseIcon,
  },
})
export default class PromptEditor extends VueWithStore {
  // Editor contains the tiptap instance
  editor: null | Editor = null;

  // The question id is specified as a prop
  @Prop({ type: String }) id!: string;

  @Prop({ type: Boolean }) readOnly!: boolean;

  mounted() {
    if (!this.readOnly) {
      // Revive existing content to vuex store
      this.$store.commit('revivePromptResponse', {
        promptId: this.id,
        key: 'content',
      });
    }

    const revivedContent = this.$store.getters.promptResponse(this.id, 'content') || '';

    // Initialize tiptap
    this.editor = new Editor({
      extensions: [
        new Blockquote(),
        new BulletList(),
        new HardBreak(),
        new Heading({ levels: [1, 2, 3] }),
        new HorizontalRule(),
        new ListItem(),
        new OrderedList(),
        new Link(),
        new Bold(),
        new Italic(),
        new Strike(),
        new Underline(),
        new History(),
        // new Image(),
      ],

      // Set initial content
      content: revivedContent,

      // Is the content able to be edited
      editable: !this.readOnly,

      // Callback for editor updates
      onUpdate: ({ getJSON }) => {
        if (this.readOnly) return;
        // JSON is used for security reasons
        const content = getJSON();

        // Store updates to local storage and timestamp
        this.$store.commit('setPromptResponse', {
          promptId: this.id,
          key: 'content',
          value: content,
        });
      },
    });
  }

  // Link url for adding/modifying links
  linkUrl: null | string = null;

  // Is the link menu active
  linkMenuActive = false;

  // Is the editor menu expanded on mobile
  expandedMobileMenu = false;

  // Toggle expansion of the menu bar
  toggleMenu() {
    this.expandedMobileMenu = !this.expandedMobileMenu;
  }

  // Hide link modifications
  hideLinkMenu() {
    this.linkMenuActive = false;
  }

  // Show link modifications
  showLinkMenu(href = null) {
    this.linkMenuActive = true;
    this.linkUrl = href;
  }

  // Update the editor link url
  updateLinkUrl(command: (input: { href: string }) => void, url: string) {
    command({ href: url });
    this.hideLinkMenu();
  }

  // Clear tiptap from memory on unmount
  beforeDestroy() {
    if (this.editor) this.editor.destroy();
  }
}
