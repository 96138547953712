
import { Component, Vue } from 'vue-property-decorator';
import { post } from '@/shared/requests';
import Longform from '../components/Longform.vue';
import MultiChoice from '../components/MultiChoice.vue';
import TrueFalse from '../components/TrueFalse.vue';
import './Prompt.scss';

@Component({
  components: {
    Longform,
    MultiChoice,
    TrueFalse,
  },
})
export default class Prompt extends Vue {
  journal: {
    id: string;
    dueAt: number;
    name: string;
    promptOrder: string[];
  } | null = null;

  prompts: Record<
    string,
    {
      id: string;
      question: string;
      type: 'longform' | 'truefalse' | 'selectone' | 'selectmany';
      options?: string[];
    }
  > = {};

  loading = true;

  loadingSubmission = false;

  disabled = false;

  error = '';

  success = '';

  timeRegex = /:\d{2}(?=[^:])/;

  async mounted() {
    await this.getSpecifics();
  }

  async getSpecifics() {
    const { journalId } = this.$route.params;
    const { success, data, error } = await post('/student/journals/specifics', { journalId });

    if (success && data) {
      const prompts = (data.prompts || ([] as { id: string }[])).reduce(
        (a: Record<string, any>, b: { id: string }) => {
          a[b.id] = b;
          return a;
        },
        {},
      );
      const responses = (data.responses || ([] as { promptId: string }[])).reduce(
        (a: Record<string, any>, b: { promptId: string }) => {
          a[b.promptId] = b;
          return a;
        },
        {},
      );

      delete data.prompts;
      delete data.responses;

      this.$set(this, 'journal', data);
      this.$set(this, 'prompts', prompts);
      this.$set(this, 'loading', false);

      if (data.dueAt + 72 * 60 * 60 < Date.now() / 1000) this.$set(this, 'disabled', true);
      if (data.completed) {
        this.$store.commit('injectResponseSet', responses);
        this.$set(this, 'disabled', true);
      }
    } else if (error) {
      console.error(error);
      this.$set(this, 'loading', false);
      this.$set(this, 'error', 'Unable to get journal details.');
    }
  }

  async submitJournal() {
    if (!this.journal || this.disabled) return;

    const submissions = this.journal.promptOrder.reduce((a, b) => {
      const type = this.prompts[b] && this.prompts[b].type;
      let dataKey = 'selections';
      if (type === 'longform') {
        dataKey = 'content';
      } else if (type === 'truefalse') {
        dataKey = 'isTrue';
      }
      a[b] = this.$store.getters.promptResponse(b, dataKey);
      if (type === 'longform') {
        a[b] = JSON.stringify(a[b]);
      } else if (type === 'selectone') {
        const selectionMap = a[b];
        a[b] = undefined;
        Object.keys(selectionMap ?? {}).forEach(c => {
          if (selectionMap[c]) a[b] = c;
        });
      } else if (type === 'selectmany') {
        const selectionMap = a[b];
        a[b] = [];
        Object.keys(selectionMap ?? {}).forEach(c => {
          if (selectionMap[c]) a[b].push(c);
        });
      }
      return a;
    }, {} as Record<string, any>);

    // Perform instant validation
    let validationError = false;
    Object.keys(submissions).forEach(promptKey => {
      let { question } = this.prompts[promptKey];
      if (question.length > 35) question = `${question.substr(0, 30).trim()}...`;

      if (this.prompts[promptKey].type === 'longform') {
        try {
          let text = '';
          const raw = JSON.parse(submissions[promptKey]);

          const getText = (data: Record<string, any>) => {
            if (data?.text) text += data.text;
            if (data?.content) {
              data.content.forEach(getText);
            }
          };

          getText(raw);

          if (!text.trim()) throw new Error();
        } catch (err) {
          this.$set(this, 'error', `Question "${question}" must be answered (with words).`);
          validationError = true;
        }
      } else if (this.prompts[promptKey].type === 'truefalse') {
        if (typeof submissions[promptKey] !== 'boolean') {
          this.$set(this, 'error', `Question "${question}" must be answered.`);
          validationError = true;
        }
      }
    });

    if (validationError) {
      try {
        window.scrollTo(0, 0);
      } catch (err) {
        // No-op
      }
      return;
    }

    this.$set(this, 'loadingSubmission', true);

    const { success, error, data } = await post('/student/responses/submit', {
      journalId: this.journal.id,
      responses: Object.keys(submissions).map(promptId => {
        const { type } = this.prompts[promptId];
        let dataKey = 'text';
        if (type === 'selectone') {
          dataKey = 'selection';
        } else if (type === 'selectmany') {
          dataKey = 'selections';
        } else if (type === 'truefalse') {
          dataKey = 'verdict';
        }
        return { promptId, [dataKey]: submissions[promptId] };
      }),
    });

    this.$set(this, 'loadingSubmission', false);

    try {
      window.scrollTo(0, 0);
    } catch (err) {
      // No-op
    }

    if (success && data) {
      this.$set(this, 'disabled', true);
      this.$set(this, 'success', 'Journal submitted.');

      // On success remove local store values
    } else if (error) {
      console.error(error);
      this.$set(this, 'error', 'Unable to submit your response.');
    }
  }
}
